<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-form></b-form>
        <b-row>
          <!-- name  -->
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="nameInput">Name:</label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name"
                placeholder="Name..."
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- schedule  -->
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="priority">Schedule:</label>
              <b-form-input id="schedule" v-model="editedItem.schedule" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- price  -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="priority">Price:</label>
              <b-form-input
                id="price"
                name="price"
                v-model="editedItem.price"
                type="text"
                data-vv-as="price"
                :class="{ input: true, 'is-danger': errors.has('price') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('price')" class="is-danger-text position-relative">{{
                errors.first('price')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- meta data  -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="priority">Meta Data:</label>
              <b-form-input id="metadata" v-model="editedItem.metadata" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- note  -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="priority">Note:</label>
              <b-form-input id="note" v-model="editedItem.note" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- jackpott  -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="priority">Jackpott:</label>
              <b-form-input
                id="jackpott"
                name="jackpott"
                v-model="editedItem.jackpott"
                type="text"
                data-vv-as="jackpott"
                :class="{ input: true, 'is-danger': errors.has('jackpott') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('jackpott')" class="is-danger-text position-relative">{{
                errors.first('jackpott')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- image url  -->
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input id="img-url" v-model="editedItem.image_url" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- image file  -->
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Game List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Games" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search games ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <!-- <b-col cols="4">
        <b-button variant="primary" class="float-right" @click="addItem"
          >Add</b-button
        >
      </b-col> -->
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(jackpott)="item">
        {{ formatPrice(item.item.jackpott) }}
      </template>
      <template v-slot:cell(price)="item">
        {{ formatPrice(item.item.price) }}
      </template>
      <template v-slot:cell(schedule)="item">
        {{ getMetadata(item.value) }}
      </template>
      <template v-slot:cell(metadata)="item">
        {{ getMetadata(item.value) }}
      </template>

      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1 mb-2" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1 mb-2" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger"
          >Deleted</b-badge
        >
        <b-badge class="mr-1 mb-2" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
        <b-badge class="mr-1 mb-2" v-if="item.item.maintenance_status === 1" variant="info">Maintenance</b-badge>
      </template>

      <template v-slot:cell(actions)="item">
        <span
          v-if="item.item.handler == 'keno'"
          class="action-btn mr-2"
          @click="updateCustomProducts(item.item)"
          :id="'custom_update_' + item.item.id"
          ><i class="fas fa-dice text-info icon-md"></i>
        </span>
        <span
          v-if="item.item.handler == 'keno'"
          class="action-btn mr-2"
          @click="setCustomMaintenance(item.item)"
          :id="'custom_maintain_' + item.item.id"
          ><i class="flaticon2-lock text-warning icon-md"></i>
        </span>
        <br v-if="item.item.handler == 'keno'" />
        <span class="action-btn mr-2" @click="setMaintenance(item.item)" :id="'maintain_' + item.item.id"
          ><i class="flaticon2-gear text-primary icon-md"></i>
        </span>
        <span class="action-btn mr-2" @click="editItem(item.item)" :id="'edit_' + item.item.id"
          ><i class="flaticon-edit text-success icon-md"></i>
        </span>
        <span class="action-btn mr-2" @click="deleteItem(item.item)" :id="'delete_' + item.item.id"
          ><i class="flaticon2-trash text-danger icon-md"></i>
        </span>
        <b-tooltip v-if="item.item.handler == 'keno'" :target="'custom_update_' + item.item.id" triggers="hover"
          >Update Custom Products</b-tooltip
        >
        <b-tooltip v-if="item.item.handler == 'keno'" :target="'custom_maintain_' + item.item.id" triggers="hover"
          >Toggle Custom Game Maintenance</b-tooltip
        >
        <b-tooltip :target="'maintain_' + item.item.id" triggers="hover">Toggle Maintenance</b-tooltip>
        <b-tooltip :target="'edit_' + item.item.id" triggers="hover">Edit</b-tooltip>
        <b-tooltip :target="'delete_' + item.item.id" triggers="hover">Delete</b-tooltip>
      </template>
    </b-table>
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
import { CurrentTime } from '@/utils/time.js';
import Swal from 'sweetalert2';
export default {
  name: 'Game List',
  components: {
    LightBox
  },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_name: '',
      filter_screen: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Handler', key: 'handler' },
        { label: 'Schedule', key: 'schedule' },
        { label: 'Price', key: 'price' },
        { label: 'Meta Data', key: 'metadata' },
        { label: 'Note', key: 'note' },
        { label: 'Jackpott', key: 'jackpott' },
        { label: 'Image URL', key: 'image_preview' },
        { label: 'Status', key: 'status', class: 'text-center' },
        { label: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      screenPosList: [],
      screenAdsList: [],
      posList: [],
      affiliateList: [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' }
      ],
      selectedGames: null,
      selectedPos: null,
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        handler: '',
        schedule: '',
        price: '',
        metadata: '',
        note: '',
        image_url: '',
        status: '',
        jackpott: ''
      },
      defaultItem: {
        id: '',
        name: '',
        handler: '',
        schedule: '',
        price: '',
        metadata: '',
        note: '',
        image_url: '',
        status: '',
        jackpott: ''
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'HH:mm DD/MM/YYYY',
        sideBySide: true
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.price ||
        this.errors.has('price') ||
        !this.editedItem.jackpott ||
        this.errors.has('jackpott')
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Games',
        route: 'games'
      },
      { title: 'Games' }
    ]);
  },
  methods: {
    setMaintenance(item) {
      Swal.fire({
        title: `Do you want to set ${item.name}'s maintenance status to ${
          item.maintenance_status ? 'ENABLED' : 'DISABLED'
        }?`,
        // text: ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${item.maintenance_status ? 'ENABLED' : 'DISABLED'}`,
        confirmButtonColor: `${item.maintenance_status ? '#34bfa3' : '#fd397a'}`,
        showDenyButton: true,
        denyButtonText: `${item.maintenance_status ? 'ENABLED SILENT' : 'DISABLED SILENT'}`,
        denyButtonColor: `${item.maintenance_status ? '#34e3a3' : '#fc7a'}`,
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed || res.isDenied) {
          ApiService.put(
            `games/${item.id}/maintenance?sendNotify=${res.isConfirmed ? 1 : 0}`,
            {},
            { 'Content-Type': 'application/json' }
          )
            .then((resp) => {
              if (resp.status == 204 || resp.data.status)
                Swal.fire({
                  icon: 'success',
                  title: `${item.name}'s maintenance status ${item.maintenance_status ? 'ENABLED' : 'DISABLED'}`,
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  this.fetchData();
                });
              else
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: resp.data.data.message,
                  showConfirmButton: false,
                  timer: 3000
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: error,
                showConfirmButton: false,
                timer: 3000
              });
            });
        }
      });
    },
    updateCustomProducts(item) {
      Swal.fire({
        title: `Do you want to update ${item.name}'s custom game products?`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          ApiService.put(`games/custom-products`, {}, { 'Content-Type': 'application/json' })
            .then((resp) => {
              if (resp.status == 204 || resp.data.status)
                Swal.fire({
                  icon: 'success',
                  title: `${item.name}'s custom game products updated`,
                  showConfirmButton: false,
                  timer: 3000
                });
              else
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: resp.data.data.message,
                  showConfirmButton: false,
                  timer: 3000
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: error,
                showConfirmButton: false,
                timer: 3000
              });
            });
        }
      });
    },
    setCustomMaintenance(item) {
      const metadata = JSON.parse(item.metadata);
      Swal.fire({
        title: `Do you want to set ${item.name}'s custom game maintenance status to ${
          item.maintenance_status ? 'ENABLED' : 'DISABLED'
        }?`,
        // text: ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${metadata.additional === 0 ? 'ENABLED' : 'DISABLED'}`,
        confirmButtonColor: `${metadata.additional === 0 ? '#34bfa3' : '#fd397a'}`,
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          ApiService.put(`games/${item.id}/custom_maintenance`, {}, { 'Content-Type': 'application/json' })
            .then((resp) => {
              if (resp.status == 204 || resp.data.status)
                Swal.fire({
                  icon: 'success',
                  title: `${item.name}'s custom game maintenance status ${
                    item.maintenance_status ? 'ENABLED' : 'DISABLED'
                  }`,
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  this.fetchData();
                });
              else
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: resp.data.data.message,
                  showConfirmButton: false,
                  timer: 3000
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: error,
                showConfirmButton: false,
                timer: 3000
              });
            });
        }
      });
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      // this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsGames = ApiService.get(
        'games',
        `?name=${this.filter_name}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&offset=${offset}&limit=${limit}`
      );
      axios
        .all([adsGames])
        .then(
          axios.spread((...response) => {
            const adsGames = response[0];
            this.items = adsGames.data.data.gameList.map((ads) => {
              return {
                ...ads,
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
              };
            });
            this.rows = adsGames.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.errors.push(errors);
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.modalTitle = 'Add Games';
    },
    editItem: async function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Game';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.selectedGames = item.id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        // var deleted_at = CurrentTime();
        ApiService.delete(`games/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Games deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.errors.push(errors);
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.selectedGames = null;
      this.selectedPos = null;
      this.nameValidate = null;
      this.gameListValidate = null;
      this.invalidNameMessage = '';
      this.invalidGameListMessage = '';
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      let allValidated = true;
      if (allValidated) {
        // Get new brand/cate/vendor name for data-table
        this.editedItem.category_id = this.selectedCategory;
        let formData = new FormData();
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const element = this.editedItem[key];
            if (element !== '' && element !== null) {
              formData.append(key, element);
            }
          }
        }
        if (this.editedItem.image_url === '') {
          formData.append('image_url', '');
        }
        if (this.editedIndex > -1) {
          // Update article
          formData.append('updated_at', CurrentTime());
          axios({
            method: 'PUT',
            url: `games/${this.items[this.editedIndex].id}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.status === 204) {
                this.editedItem.image_preview = [
                  {
                    thumb: this.editedItem.image_url,
                    src: this.editedItem.image_url
                  }
                ];
                Object.assign(this.items[this.editedIndex], this.editedItem);
                this.close();
                this.$bvToast.toast(response.data.data.message, {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.errors.push(response.data.data);
              }
            })
            .catch((errors) => {
              this.errors.push(errors);
            });
        } else {
          // Add Games
          formData.append('status', 1);
          axios({
            method: 'POST',
            url: 'games',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.status) {
                this.items.unshift({
                  ...response.data.data,
                  image_preview: [
                    {
                      thumb: response.data.data.image_url,
                      src: response.data.data.image_url
                    }
                  ]
                });
                this.close();
                this.$bvToast.toast('Games added successfully', {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.errors.push(response.data.data);
              }
            })
            .catch((errors) => {
              this.errors.push(errors);
            });
        }
      }
    }
  }
};
</script>
